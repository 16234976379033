import cloudservice from "../images/cloud_service.png";
import c2 from "../images/c2.webp";
import c3 from "../images/c3.jpg";
import { useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

import "./cloud.css"
import { Box2 } from "three";
const Cloud = () => {
    const border = {
        height: "200px",

        borderStyle: "solid",
        borderWidth: "0 4px 0 0",
        borderColor: "red",


    };
    const cards = {
        borderRadius: "0",
        width: "350px",
        marginBottom: "15px",
        backgroundColor: "#f2f2f2"


    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="xl">
            <Grid container sx={{ marginTop: "3%" }}>
                <Grid item xs={12} sm={6} md={4} sx={{ marginRight: "1%" }}>
                    <h1 className="head1">Business Process Outsourcing</h1>
                    <p className="newParagraph">Accelerate the value delivery by adopting a Cloud First approach.</p>
                </Grid>
                <Grid item>
                    <img src={cloudservice} />
                </Grid>
            </Grid>
            <Grid sx={{ backgroundColor: "#f5f5f5", marginTop: "2%", padding: "2%", borderBottom: "solid 3px black" }}>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <h3 className="head3">Seize the day, secure sustainability</h3>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={6} sx={{ marginRight: "5%" }}>
                        <p className="newParagraph">During times of great change—being able to drive value is paramount. In the wake of recent societal, industry and technology disruption, business process functions, including finance, supply chain, procurement, human resources, marketing, sales and customer operations, and industry-specific services, such as health, insurance and banking, have been severely disrupted.</p>
                        <p className="newParagraph">The operating model is proving to be a linchpin. It can not only harness change but also adapt to feed the business strategy—achieving resiliency, agility, competitive advantage and better business outcomes. Accenture is helping clients to optimize and transform business processes—to be more data-driven, AI-powered and cloud enabled—to work smarter, scale faster, be more productive and eradicate costs across the enterprise with intelligent operations.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ maxWidth: "75%" }}>
                        <div style={{ borderStyle: "solid", borderColor: "#a100ff", borderWidth: "0px 0px 0px 5px", borderRadius: "0" }}>
                            <div style={{ borderStyle: "solid", borderWidth: "2px", borderColor: "gray", padding: "20px" }}>
                                <h3>The paradox of choice for CFOs</h3>
                                <blockquote>
                                    A guide for enterprise reinvention in the age of complexity.
                                </blockquote>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{ marginTop: "3%" }}>
                <Grid item xs={12}>
                    <h1>Capabilities</h1>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <p className="newParagraph">Cloud First offers a full spectrum of cloud services to help you realize the value from your investment. We know cloud is more than just technology, so our solutions encompass the workforce and culture change needed for lasting success.</p>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Cloud Data & AI</h2>
                        <p className="newParagraph">
                            Create industry and function-specific data and AI insights and
                            intelligence for businesses through cloud industry-specific data models.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Application transformation</h2>
                        <p className="newParagraph"> Harness the power of cloud and fuel innovation to create fresh sources of business value grounded in industry.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Cloud Infrastructure</h2>
                        <p className="newParagraph"> Imagine a future where IT infrastructures can monitor themselves, predict and respond to future business needs and protect and heal themselves.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Edge Computing</h2>
                        <p className="newParagraph"> Powered by the cloud, edge computing enables businesses to reimagine experiences for people, purpose, and profitability, at speed and scale.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Network</h2>
                        <p className="newParagraph">Set up a robust network infrastructure to accelerate digital transformation and improve business performance. </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Application managed services</h2>
                        <p className="newParagraph"> Future-proof your application portfolio with proactive innovation.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Sustainable IT & technologies</h2>
                        <p className="newParagraph">Digital technology is one of the greatest enablers of sustainability.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Cloud Security</h2>
                        <p className="newParagraph">Protect your IT estate with our cloud security services.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Cloud Strategy and Design</h2>
                        <p className="newParagraph">Our Technology Strategy & Advisory practice helps architect and realize exceptional business value from technology.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Cloud infrastructure managed services</h2>
                        <p className="newParagraph">
                            Adopt a new, modern approach to IT operations with infrastructure for the cloud era.
                        </p>
                    </Grid>

                </Grid>
            </Grid>

            <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%", marginTop: "3%" }}>
                <Grid sx={{display:"flex", backgroundColor: "white", padding: "2%", justifyContent: "center" }}>
                    <div style={{ marginRight: "5%" }}>
                        <div style={border}>
                        </div>
                    </div>
                    <div>
                        <h3>Full speed ahead! </h3>
                        <h2>A look back at AWS re:Invent 2023 </h2>
                        <h2><a href="">Learn more</a></h2>
                    </div>
                </Grid>
            </Grid>


            <Grid sx={{ marginTop: "3%" }}>
                <Grid item xs={12} >
                    <h1>Ecosystem Partnerships</h1>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <p className="newParagraph">No challenge is out of reach. We extend our industry expertise and comprehensive cloud services through a vast global network of market leaders and innovators to accelerate our clients’ path to value.</p>
                </Grid>
                <Grid item>
                    <h2><a href="">view more</a></h2>
                </Grid>
            </Grid>

            <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%", marginTop: "3%", borderBottom: "solid 3px black" }}>
                <Grid item xs={12} >
                    <h1>How We Work</h1>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <p className="newParagraph">Cloud First knows how to deliver mission-critical, core business solutions in the cloud. After assessing your goals, we’ll work with you to define the right cloud solution for your business needs and get to value faster.</p>
                </Grid>
                <Grid container >
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h3 >An unmatched ecosystem of partners </h3>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h3 >Proven, industry-tailored cloud solutions </h3>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h3 >An unmatched ecosystem of partners </h3>
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{ marginTop: "3%" }}>

                <Grid item xs={12} >
                    <h1>Awards and recognition</h1>
                </Grid >
                <Grid item xs={12} sm={6} >
                    <p className="newParagraph">Capturing the full business value from cloud is as crucial as it is complicated. Find out why Accenture is the right partner to help you define and implement the right solutions for your business.</p>
                </Grid>

                <Grid container >
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ position: "relative", backgroundColor: "#f5f5f5", padding: "2%", margin: "1%" }}>
                        <h2>IDC MARKETSCAPE </h2>
                        <h3 style={{ marginBottom: "10%" }}><a href="">Leader in Worldwide Cloud Professional Services</a></h3>
                        <p style={{ position: "absolute", bottom: "0" }}>2021</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ position: "relative", backgroundColor: "#f5f5f5", padding: "2%", margin: "1%" }}>
                        <h2>EVEREST PEAK MATRIX </h2>
                        <h3 style={{ marginBottom: "10%" }}><a href="">Leader in Cloud Services for both Europe and North America</a></h3>
                        <p style={{ position: "absolute", bottom: "0" }}>2021</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ position: "relative", backgroundColor: "#f5f5f5", padding: "2%", margin: "1%" }}>
                        <h2>EVEREST PEAK MATRIX  </h2>
                        <h3 style={{ marginBottom: "10%" }}><a href="">Leader in Mainframe Services</a></h3>
                        <p style={{ position: "absolute", bottom: "0" }}>2021</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ position: "relative", backgroundColor: "#f5f5f5", padding: "2%", margin: "1%" }}>
                        <h2>GARTNER MAGIC QUADRANT</h2>
                        <h3 style={{ marginBottom: "10%" }}><a href="">Leader in Public Cloud IT Transformation Services</a></h3>
                        <p style={{ position: "absolute", bottom: "0" }}>2021</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ position: "relative", backgroundColor: "#f5f5f5", padding: "2%", margin: "1%" }}>
                        <h2>GARTNER MAGIC QUADRANT</h2>
                        <h3 style={{ marginBottom: "10%" }}><a href="">Leader in Data Center Outsourcing and Hybrid Infrastructure Managed Services (DCO/HIMS)</a></h3>
                        <p style={{ position: "absolute", bottom: "0" }}>2021</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ position: "relative", backgroundColor: "#f5f5f5", padding: "2%", margin: "1%" }}>
                        <h2>h3EVEREST PEAK MATRIX  </h2>
                        <h3 style={{ marginBottom: "10%" }}><a href="">Leader in System Integrator Capabilities on Amazon Web Services (AWS), Google Cloud Platform (GCP) and Microsoft Azure</a></h3>
                        <p style={{ position: "absolute", bottom: "0" }}>2021</p>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export default Cloud;