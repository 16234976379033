import nr1 from "../images/nr1.jpg";
import React from "react";
import "./NaturalResources.css";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/card";
import nr2 from "../images/nr2.webp";
import nr3 from "../images/nr3.jpg";
import { List, ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";

const EventTemplate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const border = {
    height: "200px",
    borderStyle: "solid",
    borderWidth: "0 4px 0 0",
    borderColor: "brown",
  };
  return (
    <div>
      <br></br>
      <div className="full-page-image1">
        <img src={nr1} alt="Full Page Imag" />
        <div className="text-overlayx">
          <h1>Natural Resources</h1>
          <p>
            Transforming mining, metals, forest products, and building materials
            with safety, intelligence, sustainability, analytics, and connected
            mining solutions.
          </p>
        </div>
      </div>
      <div className="row col-md-12 m-auto bg-light p-5">
        <div className="row col-md-10 m-auto bg-white">
          <div className="col-md-3">
            <div className="p-4 m-4" style={border}></div>
          </div>
          <div className="col-md-7 mt-5">
            <h4>MINING</h4>
            <h2>
              ModulusPi named a Leader in 2023 Industrial Development
              Corporation MarketScape
            </h2>
          </div>
        </div>
      </div>
      <Row md={10} className="bg-light ">
        <Row md={10} className="col col-md-10 m-auto">
          <Col md={10} className="mt-5">
            <h2 className="head2">
              Ensuring safety and sustainability in the digital era of
              operations.
            </h2>
          </Col>
        </Row>
        <Row md={10} className="col col-md-10 m-auto p-5">
          <Col md={10}>
            <p className="bg-light">
              Mining, metals, forest products, and building materials companies
              are presented with a unique chance to address industry-wide
              challenges, while also facing increased pressure to be responsible
              custodians of our resources.
            </p>
            <p>
              With extensive expertise spanning decades, we possess unparalleled
              industry knowledge, technological proficiency, and strategic
              foresight to provide holistic and cohesive solutions that drive
              differentiation, agility, and resilience.
            </p>
            <p>
              Be it assisting in autonomous operations, promoting
              sustainability, enhancing supply chain efficiency, or optimizing
              workforce productivity, our Natural Resources consulting practice
              empowers rapid and scalable growth and innovation.
            </p>
            <p>
              <List>
                <ListItem button component={Link} to="/about">
                  <h6>
                    ---To get our assistance &nbsp;&nbsp;&nbsp;
                    <b>
                      <u>Contact Us</u>
                    </b>
                  </h6>
                </ListItem>
              </List>
              <h6>
                ModulusPi helps the mining, metals, forest products and building
                materials industries drive sustainable growth.
              </h6>
            </p>
          </Col>
        </Row>
      </Row>

      <div class="row col-md-10 m-auto">
        <div className="full-screen-line1"></div>
        <div className="col-md-8">
          <h1 className="">
            <b>
              We center our attention and specialize in the following domains:
            </b>
          </h1>
          <p>
            Our Natural Resources practice offers solutions that can boost
            safety, empower workers, optimize operations, promote
            sustainability, and much more for your company's needs.
          </p>
        </div>
      </div>
      <div className="row col-md-10 m-auto">
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Sustainability</b>
              </Card.Title>
              <Card.Text>
                {" "}
                Shift to low-carbon energy, embrace green IT, cloud, and
                software, and upskill workers to foster circularity.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Talent & Organization</b>
              </Card.Title>
              <Card.Text>
                {" "}
                Enable workforce transformation through enhancing the synergy
                between individuals and technology.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>SAP services & solutions</b>
              </Card.Title>
              <Card.Text>
                Ignite your intelligent enterprise and propel your business
                towards advancement.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Supply chain & Operations</b>
              </Card.Title>
              <Card.Text>
                {" "}
                Enhance operational agility and optimize innovation cycles
                through intelligent, customer-centric supply chain management.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Digital Production & Operations</b>
              </Card.Title>
              <Card.Text>
                Embrace cloud, data, and AI in digital manufacturing for a
                sustainable and profitable enterprise.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Connected Mine</b>
              </Card.Title>
              <Card.Text>
                Unearth the potential for mining operations to be safer,
                sustainable, cleaner, more profitable, and smarter through
                deeper exploration.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="full-screen-line1"></div>
      </div>
      <Row md={10} className="bg-light ">
        <Row md={10} className="col col-md-10 m-auto">
          <Col md={10} className="mt-5">
            <h2
              className="head2"
              style={{ marginBottom: "50px", color: "grey" }}
            >
              ModulusPi : An industry frontrunner in optimizing mining
              operational processes.
            </h2>
          </Col>
          <div className="column">
            <p className="para">
              -ModulusPi has been recognized as a Leader in the IDC MarketScape:
              Worldwide Professional Services Firms for Mining Operational
              Process Optimization 2023 Vendor Assessment.<br/><br/>
              -The IDC MarketScape study thoroughly assesses the capabilities and
              strategies of IT and professional services firms that offer their
              expertise to mining companies on a global scale. ModulusPi's
              strengths highlighted in the assessment include:<br/><br/>
              1. ModulusPi is highly regarded as a trusted and preferred partner among its customers, primarily for its expertise in forming multidisciplinary teams that combine capabilities in strategy, digital skills, project management, BPO, and partnerships.<br/><br/>
              2. The company consistently allocates resources to research and development, fostering digital and business innovation. This commitment allows them to cultivate novel solutions and innovative approaches that effectively address operational challenges faced by mining clients.<br/><br/>
              3. With substantial experience in the mining sector, ModulusPi possesses a significant scale advantage. Furthermore, their strong ecosystem of strategic, technological, and academic partners enables them to serve the entire mining value chain, delivering transformative operational and business outcomes for their clients.
            </p>
          </div>
        </Row>
      </Row>
      <Row md={12} className="bg-white mt-5">
        <Row md={10} className="col-md-10 mx-auto">
          <h1 className="d-inline-block col-md-6">What we Think</h1>
        </Row>
        <Row md={10} className="col-md-10 mx-auto">
          <Col md={8} className="">
            <Row className="row mb-4 p-4">
              <Col className="col-md-4">
                <img
                  src={nr3}
                  style={{ width: "180px", height: "180px" }}
                ></img>
              </Col>
              <Col className="col-md-8">
                <h6>
                  <b>
                    <u>Talent & Organization</u>
                  </b>
                </h6>
                <h4>Shaping the mining and metals talent of tomorrow.</h4>
                <p>
                  In collaboration with the World Economic Forum, our report
                  reveals strategies for mining and metals companies to attract,
                  engage, and retain future talent.
                </p>
              </Col>
            </Row>
            <div className="full-screen-line1"></div>
            <Row className="row mb-4 p-4">
              <Col className="col-md-4">
                <img
                  src={nr2}
                  style={{ width: "180px", height: "180px" }}
                ></img>
              </Col>
              <Col className="col-md-8">
                <h6>
                  <b>
                    <u>Sustainability</u>
                  </b>
                </h6>
                <h4>
                  The emerging role of mining as a champion of decarbonization.
                </h4>
                <p>
                  In our Global Institutional Investor Study of ESG in Mining,
                  we uncover the drivers influencing investment choices in
                  mining companies.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>

     
    </div>
  );
};

export default EventTemplate;
