import image1 from "../images/banking&finance.jpg";
import image2 from "../images/bs1.jpeg";
import React from "react";
import "./banking_and_finance.css";
import { Row, Col } from "react-bootstrap";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useEffect } from "react";
import Divider from '@mui/material/Divider';

const EventTemplate = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="xl">

      <div style={{ marginTop: "3%", position: "relative" }}>
        <div className="newFullPageImage">
          <img src={image1} alt="Full Page Image" />
        </div>
        <div className="newTextOverLay ">
          <h1>Banking and Finance</h1>
          <p>
            Empowering banks to excel through technology, workforce, and change.
          </p>
        </div>
      </div>

      <Grid sx={{ backgroundColor: "#f5f5f5", marginTop: "3%", padding: "2%", borderBottom: "solid 3px black" }}>

        <Grid item>
          <h1>
            Banks must now question and challenge everything.
          </h1>
        </Grid>

        <Grid item>

          <p className="newParagraph">
            The banking industry has experienced fragmentation and
            simplification, driven by challenger banks and big tech companies
            disrupting the value chain. This new normal offers collaboration
            opportunities, but it also forces banks to choose between
            embracing ongoing technological innovation or losing their
            competitive edge.
          </p>
          <p className="newParagraph">
            However, this extends beyond mere digital transformation. As the
            banking sector loses market share and revenue to industries like
            big tech, we strongly believe that banks must now confront their
            deeply ingrained conventions and beliefs. To maintain their
            relevance, they must gain a comprehensive understanding of their
            markets and the necessary strategies for success within each. This
            will require them to embrace new business models, form
            unconventional partnerships, rediscover their purpose and
            humanity, and demonstrate a steadfast commitment to continuous
            innovation.
          </p>
          <p className="newParagraph">
            <b>Moduluspi</b> is ready to offer assistance, not just as a
            vendor, but as a strategic partner. We are prepared to share both
            the risks and rewards, providing valuable technological expertise
            and a global perspective. With the ability to deliver, we enable
            flexible cloud-based architectures using cutting-edge tools and
            solutions. By harnessing technology to unlock the value within
            your data, we aim to create exceptional customer experiences. We
            stand alongside you, putting our reputation on the line, and
            fulfilling our commitment to leveraging technology and human
            ingenuity.
          </p>

        </Grid>
      </Grid>

      <Grid sx={{ marginTop: "3%", border: "1px solid #e0e0e0" }}>
        <Grid item xs sx={{ padding: "2%", display: "flex", justifyContent: "center"  }}>
          <div className="full-image">
            <img src={image2} alt="Full Page Image" />
          </div>
        </Grid>

        <Divider flexItem></Divider>

        <Grid item xs sx={{ padding: "2%"}}>
       
          <h2><b>Our Banking proficiencies</b></h2>
       
          <ul className="ul1">
            <li>Core Banking</li>
            We build intelligent, connected and flexible core banking systems fit for the future.
            <li>Lending</li>
            We help commercial & retail lenders rebuild credit operations to thrive in the digital economy—and beyond.
            <li>Banking cloud</li>
            We help unlock cloud’s true benefits with the right strategy, people and tools to migrate it, operate it and secure it.
            <li>Sustainable banking</li>
            We help banks visualize and execute their sustainability agendas for first-mover advantage.
            <li>Digital Risk & Compliance</li>
            Helping banks navigate compliance; cyber risk; fraud & financial crime; and analytics & technology.
          </ul>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventTemplate;
