import React, { useRef, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, CssBaseline, Grid, Button } from '@material-ui/core';
import titleWhite from '../assets/logo/titleWhite.png'
import PaperMail from '../assets/images/paper_mail.png';
import AOS from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    root: {},

    footer: {
        margin: '50px 0px 0px 0px',
        background: theme.palette.primary.main,
        color: 'white',
        // backgroundColor:
        // 	theme.palette.type === 'light'
        // 		? theme.palette.grey[200]
        // 		: theme.palette.grey[800],
    },
    footer_content: {
        padding: '30px 0px 0px 0px',
    },
}));


const Base = () => {
    const classes = useStyles();
    const theme = useTheme();
    const navRef = useRef();
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);
    return (
        <div>				<footer className={classes.footer}>
            <div>
                <div className={classes.footer_content}>
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                                <img src={titleWhite} height="200" style={{ margin: '-58px 0px' }} width="auto" alt="" data-aos="fade-up" />
                                <p style={{ margin: '0px 12px ' }}>	ModulusPI is dedicated to making IT Services faster and simpler, with superior customer support along the way. </p>

                            </Grid>
                            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}></Grid>
                            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}></Grid>
                            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                                <div style={{ margin: '0px 12px' }}>
                                    <h4 data-aos="fade-up">Links</h4>
                                    <div style={{ margin: '46px 0px 0px 0px' }}>
                                        <Button
                                            style={{
                                                textTransform: 'capitalize',
                                                background: '#651FFF',
                                                color: 'white',
                                                fontWeight: '400',
                                                borderRadius: '0px', marginLeft: '-8px'
                                            }}
                                            component={Link}
                                            to="/about"
                                        >
                                            About

                                        </Button><br />
                                        <Button
                                            style={{
                                                textTransform: 'capitalize',
                                                background: '#651FFF',
                                                color: 'white',
                                                fontWeight: '400',
                                                borderRadius: '0px', marginLeft: '-8px'
                                            }}
                                            component={Link}
                                            to="/services"
                                        >

                                            Services

                                        </Button><br />
                                        <Button
                                            style={{
                                                textTransform: 'capitalize',
                                                background: '#651FFF',
                                                color: 'white',
                                                fontWeight: '400',
                                                borderRadius: '0px', marginLeft: '-8px'
                                            }}
                                            component={Link}
                                            to="/careers"
                                        >

                                            Careers

                                        </Button>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                                <div style={{ margin: '0px 12px' }}>
                                    <h4 data-aos="fade-up">Social</h4>
                                    <div style={{ margin: '46px 0px' }}>
                                        <Button
                                            style={{
                                                textTransform: 'lowercase',
                                                background: '#651FFF',
                                                color: 'white',
                                                fontWeight: '400',
                                                borderRadius: '0px',
                                            }}
                                            href="mailto:sales@moduluspi.com"
                                        >
                                            <img src={PaperMail} style={{ height: '18px' }} />
                                            &nbsp;&nbsp;&nbsp;send a mail &nbsp;

                                        </Button></div>

                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                >
                    <defs>
                        <path
                            id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="parallax">
                        <use
                            xlinkHref="#gentle-wave"
                            x={48}
                            y={0}
                            fill="rgba(255,255,255,0.7"
                        />
                        <use
                            xlinkHref="#gentle-wave"
                            x={48}
                            y={3}
                            fill="rgba(255,255,255,0.5)"
                        />
                        <use
                            xlinkHref="#gentle-wave"
                            x={48}
                            y={5}
                            fill="rgba(255,255,255,0.3)"
                        />
                        <use xlinkHref="#gentle-wave" x={48} y={7} fill="#fff" />
                    </g>

                </svg>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white',
                    color: 'grey',
                    height: '100px'
                }}>
                    <p >Copyright © Moduluspi LLC | 2016-2022</p>


                </div>

            </div>
        </footer >
        </div >
    )
}

export default Base