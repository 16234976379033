import Col from "react-bootstrap/col";
import Row from "react-bootstrap/row";
import finance from "../images/finance.jpg";
import i4 from "../images/umbrella.svg";
import i2 from "../images/strategy.svg";
import i1 from "../images/trophy.svg";
import i3 from "../images/binoculars.svg";
import i6 from "../images/i6.webp";
import i5 from "../images/i5.webp";
import i44 from "../images/i4.webp";
import { useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const Application = () => {
  const bak = {
    width: "32px",

    position: "absolute",
    top: "100%",
    left: "0",
    transform: "skewY(20deg)",
    backgroundColor: "black",
    zIndex: "-1",
  };
  const border = {
    height: "200px",

    borderStyle: "solid",
    borderWidth: "0 4px 0 0",
    borderColor: "blue",
  };
  const cards = {
    position: "relative",
    top: "35px",
    zIndex: "1"
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="xl">

      <Grid container sx={{ marginTop: "3%" }}>

        <Grid item xs={12} sm={6} md={4} sx={{ marginRight: "1%" }}>
          <h1 className="head1">Enterprise Value and the CFO</h1>
          <p className="newParagraph">Architecting change for overall value</p>
        </Grid>

        <Grid item>
          <img src={finance} width="600px" height="600px" />
        </Grid>

      </Grid>

      <Grid sx={{ backgroundColor: "#f5f5f5", marginTop: "2%", padding: "2%" , borderBottom: "solid 3px black"}}>
        <Grid item xs={12} sm={6} >
          <h3 className="head3">
            Assisting the heads of finance and risk to navigate change and
            increase enterprise value
          </h3>
        </Grid>
        <Grid container sx={{ position: "relative" }}>
          <Grid item xs={12} sm={6} sx={{ marginRight: "5%" }}>
            <p className="newParagraph">
              The role of the CFO has never been more critical to the success of
              the enterprise. Amid intensifying market disruptions, escalating
              risk volatility, and accelerating customer behavior changes, the
              CFO plays a crucial role in balancing economic stewardship with
              the strategic assessment of growth risks and opportunities to
              drive enterprise value.
            </p>
            <p className="newParagraph">
              Moduluspi has extensive experience and know-how across the entire
              finance transformation journey. Our ability to predict outcomes
              with confidence empowers finance and risk executives to rapidly
              evaluate opportunities and drive the right changes across the
              enterprise. This leads to 360° value, benefitting customers,
              employees, shareholders, communities, and the environment.
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} sx={{ maxWidth: "75%" }}>
            <div
              style={{
                borderStyle: "solid",
                borderColor: "#a100ff",
                borderWidth: "0px 0px 0px 5px",
                borderRadius: "0",
              }}
            >
              <div
                className="ms-5"
                style={{
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "gray",
                  padding: "20px",
                }}
              >
                <h3>The paradox of choice for CFOs</h3>
                <blockquote>
                  A guide for enterprise reinvention in the age of complexity.
                </blockquote>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ marginTop: "3%" }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <h1>Our strengths in finance and risk</h1>
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className="newParagraph">
            How we enable CFOs to design transformation for all stakeholders.
          </p>
        </Grid>

        <Grid container columnSpacing={12}>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <img src={i1} style={cards}></img>
            <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
              <h2>Overall value creation</h2>
              <p className="newParagraph" style={{ marginBottom: "100px" }}>
                Deliver positive outcomes from core functions to field
                operations and extend finance’s impact across your enterprise
                by evaluating opportunities that benefit customers, employees,
                shareholders, communities, and the environment.
              </p>
              <h4 style={{ position: "absolute", bottom: "0" }}>
                <a href="">Learn More</a>
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <img src={i2} style={cards}></img>
            <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
              <h2>Future-ready transformation journey</h2>
              <p className="newParagraph" style={{ marginBottom: "100px" }}>
                Allowing you to select the most direct path to your
                future-ready organisation.
              </p>
              <h4 style={{ position: "absolute", bottom: "0" }}>
                <a href="">Learn More</a>
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <img src={i3} style={cards}></img>
            <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
              <h2>Predictive view</h2>
              <p className="newParagraph" style={{ marginBottom: "100px" }}>
                See beyond your company’s horizon with harmonized data
                platforms and advanced scenario modeling to quickly guide your
                organization amid changing market dynamics and answer critical
                ‘what if’ questions with confidence.
              </p>
              <h4 style={{ position: "absolute", bottom: "0" }}>
                <a href="">Learn More</a>
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <img src={i4} style={cards}></img>
            <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
              <h2>Risk& resilience</h2>
              <p className="newParagraph" style={{ marginBottom: "100px" }}>
                Keep your business strategy moving forward with pace by
                gaining visibility into the interconnected forces posing risks
                to your enterprise so you are prepared to mitigate
                vulnerabilities and threats to your resilience.
              </p>
              <h4 style={{ position: "absolute", bottom: "0" }}>
                <a href="">Learn More</a>
              </h4>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ marginTop: "3%", backgroundColor: "#f5f5f5", padding: "2%", borderBottom: "solid 3px black" }}>
        <Grid item xs={12}>
          <h1>Intelligent platforms</h1>
        </Grid>
        <Grid item xs={12} sm={8}>
          <p className="newParagraph">
            We combine the power of leading platforms—SAP, Oracle, Salesforce,
            Workday and more—with our intelligence, innovation and industry
            capabilities to drive large-scale, platform-enabled
            transformation.
          </p>
        </Grid>
      </Grid>

      <Grid sx={{ marginTop: "3%" }}>
        <Grid item xs={12}>
          <h1 className="d-inline-block col-md-6">What we Think</h1>
        </Grid>

        <Grid>
          <Grid container sx={{ marginBottom: "1%" }}>
            <Grid item sx={{ marginRight: "5%" }}>
              <img
                src={i44}
                style={{ width: "140px", height: "140px" }}
              ></img>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>Strategy & Consulting</h4>
              <h3>Total Enterprise Reinvention</h3>
              <p className="newParagraph">
                See how "Reinventors" are setting a new performance frontier
                for their companies–and entire industries.
              </p>
            </Grid>
          </Grid>

          <Grid container sx={{ marginBottom: "1%" }}>
            <Grid item sx={{ marginRight: "5%" }}>
              <img src={i5} style={{ width: "140px", height: "140px" }}></img>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>Cloud data</h4>
              <h3>A new era of generative AI for everyone</h3>
              <p className="newParagraph">
                The technology underpinning ChatGPT will transform work and
                reinvent business.
              </p>
            </Grid>
          </Grid>

          <Grid container >
            <Grid item sx={{ marginRight: "5%" }}>
              <img src={i6} style={{ width: "140px", height: "140px" }}></img>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>CFO & Enterprise Value</h4>
              <h3>Agile and active planning in a time of uncertainty</h3>
              <p className="newParagraph">
                Explore how agile and active planning can help companies
                anticipate, prepare for, and seize opportunity in an uncertain
                world.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%", marginTop: "3%" }}>
        <Grid sx={{ display: "flex", backgroundColor: "white", padding: "2%", justifyContent: "center" }} >
          <div style={{ marginRight: "5%" }}>
            <div style={border}></div>
          </div>
          <Grid item xs={12} sm={6}>
            <h4>FINANCE AT MODULUSPI </h4>
            <h2>
              Finance is being reimagined to create value for Moduluspi, our
              clients, and our people.
            </h2>
          </Grid>
        </Grid>
      </Grid>

    </Container>
  );
};
export default Application;
