import React, { useRef } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Home from './pages/Home';
import About from './pages/About';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Security from './pages/Security';
import Services from './pages/Services';
import Extended from './pages/Extended';
import Artificial from './pages/Artificial';
import Cloud from "./pages/Cloud";
import outsourcing from "./pages/Outsourcing";
import finance from "./pages/FinanceConsulting"
import Application from "./pages/Application";
import Banking_and_finance from "./pages/Banking_and_finance";
import Health from "./pages/Health";
import Retail_Ecom from "./pages/Retail_Ecom";
import Utilities from "./pages/Utilities";
import Public_sector from "./pages/Public_sector";
import LifeSciences from "./pages/LifeSciences";
import NaturalResources from "./pages/NaturalResources";
import Navbar from './components/Navbar';
import Base from './components/Base';
import { Container, CssBaseline, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: { padding: theme.spacing(0, 1), },
	content: {
		// padding: theme.spacing(0, 4),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	footer: {
		margin: '50px 0px 0px 0px',
		background: theme.palette.primary.main,
		color: 'white',
		// backgroundColor:
		// 	theme.palette.type === 'light'
		// 		? theme.palette.grey[200]
		// 		: theme.palette.grey[800],
	},
	footer_content: {
		padding: '30px 0px 0px 0px',
	},
}));

const App = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const navRef = useRef();
	return (
		<BrowserRouter>
			<CssBaseline />

			<div className={classes.root}>
				<Navbar ref={navRef} />
				<main onClick={() => navRef.current.handleDrawerClose()}>
					<div className={classes.content} />

					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/cloud" component={Cloud}/>
						<Route exact path="/application" component={Application}/>
						<Route exact path="/outsourcing" component={outsourcing}/>
						<Route exact path="/financeconsulting" component={finance}/>
						<Route exact path="/lifesciences" component={LifeSciences}/>
						<Route exact path="/naturalresources" component={NaturalResources}/>
						<Route exact path="/about" component={About} />
						<Route exact path="/services" component={Services} />
						<Route exact path="/careers" component={Careers} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/xr" component={Extended} />
						<Route exact path="/ai" component={Artificial} />
						<Route exact path="/banking_and_finance" component={Banking_and_finance}/>
						<Route exact path="/utilities" component={Utilities}/>
						<Route exact path="/public_sector" component={Public_sector}/>
						<Route exact path="/health" component={Health}/>
						<Route exact path="/retail_ecom" component={Retail_Ecom}/>
						<Route exact path="/security" component={Security}/>



					</Switch>
				</main>
				<Base />
			</div>
		</BrowserRouter>
	);
};

export default App;
