import health1 from "../images/health1.png";
import health2 from "../images/health2.png";
import React from "react";
import "./health.css";
import { Row, Col } from "react-bootstrap";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useEffect } from "react";

const EventTemplate = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="xl">
            <Grid sx={{ marginLeft: "10%", marginRight: "10%" }}>
                <Grid item xs={12} sm={8}>
                    <h1 className="header6">
                        Expert consulting for the healthcare sector
                    </h1>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <p className="text-overlay2">
                        Enhancing accessibility, optimizing experiences, and achieving
                        better outcomes through the synergy of technology and human
                        innovation.
                    </p>
                </Grid>
            </Grid>

            <div className="full-screen-line"></div>;

            <Grid sx={{ marginLeft: "10%", marginRight: "10%" }}>
                <h1 className="header8">Capabilities</h1>
                <div className="full-page-image5">
                    <img src={health1} alt="Full Page Image5" />
                </div>
            </Grid>

            <div className="full-screen-line"></div>

            <Grid sx={{ marginLeft: "10%", marginRight: "10%" }}>
                <h1 className="header8">What we think</h1>
                <div className="full-page-image5">
                    <img src={health2} alt="Full Page Image5" />
                </div>
            </Grid>

            <div className="full-screen-line"></div>;

            <Grid sx={{ marginLeft: "10%", marginRight: "10%" }}>

                <Grid item xs={12}>
                    <h2>Transform Healthcare </h2>

                </Grid>
                <Grid item xs={12} sm={8}>
                    <p className="newParagraph">Partner with clients to use the power of technology and human ingenuity to help improve access, experience, and outcomes in healthcare.
                        <a href="https://moduluspi.com/careers" className="my-link">@Modulus Pi Careers</a>
                    </p>
                </Grid>

            </Grid>

            <div className="full-screen-line"></div>;
        </Container>
    );
};

export default EventTemplate;
