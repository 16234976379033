import ls1 from "../images/ls1.jpg";
import ls2 from "../images/ls2.png";
import React from "react";
import "./LifeSciences.css";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/card";

const EventTemplate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <br></br>
      <div className="full-page-image1">
        <img src={ls1} alt="Full Page Imag" />
        <div className="text-overlay1">
          <h1>Life Sciences</h1>
          <p>
            We assist our clients in creating a significant difference in the
            lives of patients by leveraging New Science, innovative medical
            technologies, and enhanced cooperation.
          </p>
        </div>
      </div>
      <Row md={10} className="bg-light ">
        <Row md={10} className="col col-md-10 m-auto">
          <Col md={10} className="mt-5">
            <h2 className="head2">
              We are actively shaping the future of Life Sciences through
              digital means.
            </h2>
          </Col>
        </Row>
        <Row md={10} className="col col-md-10 m-auto p-5">
          <Col md={10}>
            <p className="bg-light">
              Increasing expenses, evolving patient demands, and the necessity
              to enhance research and development, service models, and
              treatments are prompting businesses to embrace a contemporary
              approach to transformation. By implementing a reinvention strategy
              that prioritizes digital technology, organizations can effectively
              propel future sustainable growth.
            </p>
            <p className="bg-light">
              <div className="full-screen-line1"></div>
              <b>
                ---Confronting the emerging reality in the biopharmaceutical
                industry.
              </b>
              <p>
                The biopharmaceutical industry was propelled into uncharted
                territory by the COVID-19 pandemic, leading to an economic
                landscape that necessitates adaptation. This is particularly
                crucial as New Science emerges as a prominent driver, projected
                to fuel approximately 81% of revenue growth between 2021 and
                2026.
              </p>
              <p>
                In order to flourish within this evolving economic landscape,
                the biopharmaceutical industry must proactively embrace New
                Science as a catalyst for growth, navigate cost reduction
                pressures, and transform into a future-ready enterprise capable
                of redefining how it generates, captures, and shares value.
                Explore further into these three crucial discoveries and delve
                into the five key strategies our research has identified to
                effectively address this new reality in the industry.
              </p>
            </p>
            <p className="bg-light">
              <div className="full-screen-line1"></div>
              <b>
                ---Facilitating impactful results through the optimization of
                research and development (R&D) processes.
              </b>
              <p>
                Following two years of disruption, the biopharmaceutical
                industry has been compelled to establish new standards for
                innovation, as well as the speed and cost associated with
                bringing treatments to the market. Moreover, the escalation of
                price pressures, with a projected affordability gap of $300
                billion in developed markets by 2028, adds strain to the
                productivity equation.
              </p>
              <p>
                This moment presents an opportune time to redefine strategies
                and revolutionize the research and development (R&D) process
                through the integration of digital capabilities and a
                patient-centric approach, leveraging data, advanced analytics,
                and technology. Through extensive research, ModulusPI has
                identified three strategic moves supported by five key
                facilitators that can assist organizations in making this
                transformation. Embracing these strategic moves will not only
                lead to significant cost reductions but also facilitate the
                adoption of New Science and bring about radical changes in the
                pathways to commercialization and access.
              </p>
            </p>
            <p className="bg-light">
              <div className="full-screen-line1"></div>
              <b>
                ---The emergence of digital health in the field of medical
                technology (MedTech).
              </b>
              <p>
                MedTech companies possess a distinct advantage in spearheading
                the digital health revolution due to their profound
                understanding of therapeutics, patients, and healthcare
                providers. The growth in this sector is primarily fueled by the
                increasing popularity of digital devices, many of which are
                already prevalent among consumers. However, in order to
                effectively harness digital insights and develop a comprehensive
                Digital Health solution, it is crucial for these companies to
                establish a robust internal digital infrastructure.
              </p>
              <p>
                To gain insights into the primary challenges faced by
                professionals in the life sciences industry when expanding, we
                conducted interviews with 30 Digital Health executives and
                C-suite leaders from the MedTech and pharmaceutical sectors
                within AdvaMed's Digital Health Center of Excellence.
                Additionally, we conducted a global survey of 150 MedTech
                executives and analyzed over 100 mergers and acquisitions deals
                along with 600 product launches from a subset of MedTech
                companies that occurred between January 2019 and May 2022. This
                extensive research unveiled five key trends that are driving the
                future growth of the MedTech industry.
              </p>
            </p>
            <p className="bg-light">
              <div className="full-screen-line1"></div>
              <b>
                ---Expanding the presence of Life Sciences into the Metaverse
                Continuum.
              </b>
              <p>
                The metaverse has arrived and is rapidly evolving, with certain
                elements already triggering significant transformations in the
                life sciences sector. According to ModulusPI research, 85% of
                Biopharma and 91% of MedTech executives anticipate a positive
                impact on their organizations as a result.
              </p>

              <p>
                Four crucial technology trends - WebMe, the Programmable World,
                the Unreal, and Computing the Impossible - are shaping the
                future of the industry within the metaverse. These metaverse
                components will facilitate genuinely personalized digital
                approaches, bringing us closer to addressing the unmet needs of
                patients, employees, and healthcare professionals in ways that
                were previously unimaginable. Explore our podcast and research
                materials to discover how technology continues to revolutionize
                the Biopharma and MedTech sectors.
              </p>
            </p>
            <div className="full-screen-line1"></div>
          </Col>
        </Row>
      </Row>
      <Row md={10} className="bg-light ">
        <Row md={10} className="col col-md-10 m-auto">
          <Col md={10} className="mt-5">
            <h2
              className="head2"
              style={{ marginBottom: "50px", color: "grey" }}
            >
              Establishing a fresh rhythm of transformation.
            </h2>
          </Col>
          <div className="column">
            <p className="para">
              The biopharmaceutical industry is being challenged by a new
              economic landscape that necessitates change. Increasing costs
              associated with New Science advancements and patient care,
              combined with a slow adoption of technology, have pushed the
              industry to a critical turning point. Companies are now faced with
              the daunting task of developing and delivering more effective,
              affordable, and personalized treatments while rapidly adapting to
              new ways of operating.
            </p>
            <p className="para">
              The solution lies in leveraging innovative technology, harnessing
              the power of New Science, tapping into human ingenuity, and
              maintaining an unwavering dedication to enhancing patient
              outcomes.
            </p>
            <p className="para">
              With a global network of over 20,000 life sciences professionals
              spanning across 50 countries, and a track record of assisting 90%
              of Fortune 500 life sciences companies across all major
              therapeutic areas, we are well-equipped to guide our clients
              through this transformative period and enable them to make a
              profound impact on the lives of patients.
            </p>
          </div>
        </Row>
      </Row>
      <div className="full-page-image3">
        <img src={ls2} alt="Full Page Image3" />
      </div>

      <div class="row col-md-10 m-auto">
        <div className="full-screen-line1"></div>
        <div className="col-md-8">
          <h1 className="">
            <b>Our core focus and expertise lie in the following areas:</b>
          </h1>
          <p>
            ModulusPI Life Sciences provides a comprehensive array of services
            in Strategy, Consulting, Operations, and Technology. Our offerings
            are dedicated to enabling the delivery of personalized healthcare
            and driving improved patient outcomes.
          </p>
        </div>
      </div>
      <div className="row col-md-10 m-auto">
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Medical Technology</b>
              </Card.Title>
              <Card.Text>
                {" "}
                Partnering with MedTech firms for holistic solutions across
                patients' healthcare journeys.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Precision Medicine</b>
              </Card.Title>
              <Card.Text>
                {" "}
                Achieving personalized care driven by data to treat patients in
                accordance with their distinct genomic characteristics.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Patient Services</b>
              </Card.Title>
              <Card.Text>
                Empowering you to enhance patient care and improve health
                outcomes through our innovative services.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Technology</b>
              </Card.Title>
              <Card.Text>
                {" "}
                Embracing new IT and innovative technology solutions to improve
                patient care and operational efficiency.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>Cloud first for life sciences</b>
              </Card.Title>
              <Card.Text>
                Uncovering data potential, enhancing engagement, and
                transforming your business through cloud technology.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className="">
            <Card.Body className="p-5">
              <Card.Title>
                <b>R & D</b>
              </Card.Title>
              <Card.Text>
                Assisting in accelerating and enhancing R&D processes to achieve
                superior outcomes.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="full-screen-line1"></div>
      </div>
    </div>
  );
};

export default EventTemplate;
