import React from 'react';
import './retail.css';
import retail1 from "../images/retail1.png";
import isvs from "../images/isvs.jpg";
import { Row, Col } from "react-bootstrap";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useEffect } from "react";

const YourComponent = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const border = {
    height: "200px",

    borderStyle: "solid",
    borderWidth: "0 4px 0 0",
    borderColor: "cadetblue",


  };
  return (
    <Container maxWidth="xl">


      <div style={{ marginTop: "2%" }}>
        <div className="full-page-image5">
          <img src={retail1} alt="Full Page Image5" />
        </div>
      </div>
      <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%", borderBottom: "3px solid black" }}>
        <Grid>
          <div>
            <h1>
              Expertise....
            </h1>
          </div>

          <div>
            <h3>....Store of Tomorrow....</h3>
            <p className="newParagraph">
              Reset your stores to fully integrate online and offline to deliver services and experiences that meet evolving consumer expectations.
            </p>
            <h3>....ai.RETAIL....</h3>
            <p className="newParagraph">
              Bringing AI-powered solutions to transform marketing, merchandising and supply chain for retailers.            </p>
            <h3>....Retail ESG & Sustainability....</h3>
            <p className="newParagraph">
              Integrate environmental, social and governance into every retail department and business unit to connect profit with sustainability.</p>
            <h3>....Retail Sourcing....</h3>
            <p className="newParagraph">
              Reset retail sourcing to be a strategic asset for retailers, while pivoting the function to digital ways of working.            </p>
            <h3>....Retail Supply Chain....</h3>
            <p className="newParagraph">
              Futureproof your local supply chain to strategically meet shifting consumer preferences, including last mile and sustainable delivery.            </p>
            <h3>....Retail Workforce....</h3>
            <p className="newParagraph">
              Redefine the future of work and create omni-connected experiences to skill and scale a future-ready retail frontline and corporate workforce.            </p>
            <h3>....Digital Retail....</h3>
            <p className="newParagraph">
              Transform your customer shopping experience across digital channels and stores, and re-shape P&L to adapt profitably to the changing landscape.            </p>
            <h3>....Retail Cloud....</h3>
            <p className="newParagraph">
              Futureproof your technology with cloud and develop ecosystem partnerships to enable consumer experiences with speed and agility.            </p>
            <h3>....Retail Merchandising....</h3>
            <p className="newParagraph">
              The role 'retail merchant' is being reinvented through automation to provide predictive insights.</p>
          </div>
        </Grid>


        <Grid sx={{ marginTop: "3%", display: "flex", backgroundColor: "white", padding: "2%", justifyContent: "center" }}>
          <div style={{ marginRight: "5%" }}>
            <div style={border}>
            </div>
          </div>

          <div>
            <h1 style={{ color: "cadetblue" }}>A new reality for the near future of retailing </h1>
          </div>
        </Grid>

        <Grid sx={{ marginTop: "3%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

          <h1>Responsible transformation of the retail industry. </h1>
          <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
            <p className="lastpara">The time has come to embrace a new culture of continuous resetting. By adopting the appropriate strategy, leveraging data, and maintaining agility, every retailer has the opportunity to reset, improve, and expand. In addition to making purpose-driven commitments to environmental, social, and governance (ESG) principles, and adopting modern digital technologies like cloud computing, retailers must prioritize six key imperatives for growth: consumer offerings, channels, fulfillment, sourcing, talent, and data. This pivotal moment represents the true test for the retail industry.
            </p>
            <p className="lastpara">The crucial task is to responsibly reset the business and maintain the agility to continuously adapt and seek profitable growth in a constantly evolving and challenging world.</p>
          </div>

        </Grid>
      </Grid>
    </Container>
  );
};

export default YourComponent;
