import u1 from "../images/utilities1.png";
import u2 from "../images/utilities2.png";
import sec1 from "../images/security1.jpg";
import React from "react";
import { Row, Col } from "react-bootstrap";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useEffect } from "react";

const EventTemplate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container maxWidth="xl">
            <div className="full-page-image5">
                <img src={u1} alt="Full Page Image5" />
            </div>
            <div className="full-page-image5">
                <img src={u2} alt="Full Page Image5" />
            </div>

            <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%" }}>

                <h1 className="head2">What we do....?</h1>

                <div>
                    <p className="newParagraph">Empowering utilities and energy providers to achieve new levels of performance and accelerate the path toward a net-zero future. </p>
                </div>
            </Grid>

            <Grid container sx={{ marginTop: "3%", border: "1px solid #e0e0e0" }}>

                <Grid item xs sx={{ padding: "2%" }}>
                    <h2 className="header"><b>Who we help</b></h2>
                    <ul className="ul1">
                        <li>Houston Cyber Fusion Center</li>
                        Modulus Pi's purpose-built, one-of-a-kind Cyber Fusion Center for operational technology (OT) security and industrial control systems (ICS).
                        <li>Global Cyber Fusion Centers</li>
                        A collective of global security professionals, leading data scientists, renowned academic researchers as well as technology and alliance partners.
                        <li>D.C. Cyber Fusion Center</li>
                        The Cyber Fusion Center in Washington, D.C., combines threat intelligence, adversary simulations, incident response and cybersecurity R&D.
                        Helping banks navigate compliance; cyber risk; fraud & financial crime; and analytics & technology.
                    </ul>
                </Grid>
                <Divider orientation="vertical" flexItem>
                </Divider>

                <Grid item xs sx={{ padding: "2%" }}>
                    <h2 className="header"><b>How we help</b></h2>
                    <ul className="ul1">
                        <p>Modulus Pi addresses how traditional identity and access management (IAM) and digital identity are complementary tools. Both help organizations develop trust, ensure privacy, increase security and provide an excellent user experience. As the world evolves to expand digital experiences and blend them with authentic in-person interactions, the importance of enhancing existing IAM roadmaps and taking advantage of digital identity capabilities increases.<br></br></p><p>Modulus Pi partners with Cyber Million, an initiative to help recruit entry-level candidates with cyber skills on the Immersive Labs platform.

                        </p>
                    </ul>
                </Grid>

            </Grid>
        </Container>
    );
};
export default EventTemplate;