import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AOS from 'aos';
import "aos/dist/aos.css";
import {
	Fade,
	Grid,
	Container,
	Card,
	CardContent,
	Typography,
	Button,
} from '@material-ui/core';

import RightIcon from '../assets/images/right_icon.png';
import Animated from '../Animated.svg';

const useStyles = makeStyles((theme) => ({
	root: {},
	wrapper: {},
	section_one: {
		padding: '150px 0px 150px 0px',
		clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		[theme.breakpoints.down('md')]: {
			clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
		},
		[theme.breakpoints.down('xs')]: {
			clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
		},
	},
	section_two: {
		padding: '150px 0px',
		background: theme.palette.primary.main,
		color: 'white',
		clipPath: 'polygon(0 0, 100% 12%, 100% 100%, 0 88%)',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		[theme.breakpoints.down('md')]: {
			clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
		},
		[theme.breakpoints.down('xs')]: {
			clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
		},
	},
	section_three: {
		padding: '150px 0px',
		paddingBottom: '150px',
		clipPath: 'polygon(0 0, 100% 12%, 100% 100%, 0 88%)',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		[theme.breakpoints.down('md')]: {
			clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
		},
		[theme.breakpoints.down('xs')]: {
			clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
		},
		color: 'grey',
	},

	card: {
		margin: theme.spacing(2),
		borderRadius: '0px',
		'&:hover': {
			transform: 'scale(1.05)',
		},
		transition: 'all .2s ease-in-out',
		background: theme.palette.primary.main,
		color: 'white',
	},
	card_content: {},
	svg: {
		marginTop: '-100px',
		[theme.breakpoints.up('sm')]: {
			marginTop: '-250px',
		},
	},
	visual_subtitle: {
		fontFamily: 'Poppins',
		fontWeight: 'bold',
		fontSize: '28px',

		textAlign: 'center',
		background: 'linear-gradient(90deg, #fc466b 0%, #3f5efb 100%)',
		backgroundSize: '100%',
		backgroundRepeat: 'repeat',
		/* Use the text as a mask for the background. */
		/* This will show the gradient as a text color rather than element bg. */
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',
		color: 'transparent',
		marginBottom: '50px',
		padding: '0',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2em',
		},
		'&:hover': {
			textFillColor: 'transparent',
			textStrokeColor: 'currentColor',
			textStrokeWidth: '0.5px',
		},
	},
}));

const Careers = () => {
	const classes = useStyles();
	const [mount, setMount] = React.useState(false);

	useEffect(() => {
		handleMount();

		return () => { };
	}, []);

	const handleMount = () => {
		setMount(true);
	};
	useEffect(() => {
		AOS.init({
			duration: 2000
		});
	}, []);

	const cardData = [
		{
			designation: 'Backend Developer',
			tech: 'ORACLE DBA',
			experience: '3-7 years',
			location: 'Hyderabad',
			info: '',
		},
		{
			designation: 'UI developer / UX Designer',
			tech: 'Adobe XD/ Figma',
			experience: '0-1 years',
			location: 'Hyderabad',
			info: '',
		},
		{
			designation: 'Developer',
			tech: 'Informatica',
			experience: '3-7 years',
			location: 'Hyderabad',
			info: '',
		},
	];

	return (
		<div className={classes.root}>
			<Fade in={mount} timeout={1000} mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<div className={classes.section_one}>
						<Grid container align="center">
							<Container>
								<Grid item xs={10} sm={10} md={10} lg={8}>
									<h1 className={classes.visual_subtitle} data-aos="fade-up">Opportunities are infinite</h1>
									<Button
										style={{
											textTransform: 'lowercase',
											background: '#651FFF',
											color: 'white',
											fontWeight: '400',
											borderRadius: '0px',
										}}
										href="#paper"
									>
										&nbsp;&nbsp;&nbsp;find yours &nbsp;
										<img src={RightIcon} style={{ height: '18px' }} />
									</Button>
									<h3 style={{ color: 'grey' }}>
										Start your future now, Whether you're just beginning your
										career or taking it to the next level, we have the right job
										for you. Apply today and join our team of experts!
									</h3>
									{/* <object
										type="image/svg+xml"
										data={Animated}
										className={classes.svg}
									>
										svg-animation
									</object> */}
								</Grid>
							</Container>
						</Grid>
					</div>
					<div className={classes.section_two}>
						<Container maxWidth="sm" align="center">

							<h1 data-aos="fade-up">Join the teams building tomorrow’s software</h1>
							<Button
								style={{
									textTransform: 'lowercase',
									background: '#651FFF',
									color: 'white',
									fontWeight: '400',
									borderRadius: '0px',
								}}
								href="mailto:hr@moduluspi.com"
							>
								&nbsp;&nbsp;&nbsp;send your resume&nbsp;
								<img src={RightIcon} style={{ height: '18px' }} />
							</Button>

						</Container>
					</div>

					<div id="paper" className={classes.section_three}>
						<Container maxWidth="md" align="center" disableGutters='true'>
							<div className='letter'>
								<h1 className={classes.visual_subtitle} data-aos="fade-up">
									Why<span> ModulusPi? </span>
								</h1>
								<h4>
									simply because, ModulusPi is the nucleus of all great minds at
									work. to elaborate more on the why, here’s a thought experiment
									for you:
								</h4>
								<br />
								<br />
								<h1
									style={{
										border: '2px solid white',
										borderRadius: '50px',
										width: '40px',
										height: '40px',
									}}
									gutterBottom
								>
									1
								</h1>

								<h4>
									put a group of incredibly passionate, driven individuals
									together.
								</h4>
								<br />
								<h1
									style={{
										border: '2px solid white',
										borderRadius: '50px',
										width: '40px',
										height: '40px',
									}}
								>
									2
								</h1>
								<h4>
									give them the complete freedom to chase down their goals in a
									complete uninhibited manner.
								</h4>
								<br />
								<h1
									style={{
										border: '2px solid white',
										borderRadius: '50px',
										width: '40px',
										height: '40px',
									}}
								>
									3
								</h1>
								<h4>you will have ModulusPi.</h4>
								<br />
								<br />
								<h4>
									ModulusPi manifesto is crowd sourced from these very people,
									all rooted in different backgrounds but sharing the same ethos;
									to continuously push oneself and in the process, the community.
								</h4>
							</div>
						</Container>
					</div>
				</div>
			</Fade>
		</div>
	);
};
export default Careers;
