import n from "../images/Network-Effect.webp";
import sec from "../images/security.png";
import scale from "../images/scale.png";
import code from "../images/coding.webp";
import auto from "../images/a.png";
import f from "../images/fl.png";
import c from "../images/clipboard.png";
import r from "../images/rotate.webp";
import cl from "../images/cloud.webp";
import wwt from "../images/accenture-fs-2021-400x400 (1).webp";
import wwt2 from "../images/TV-2023-main-visual-4K_1x1.webp";
import { useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import zIndex from "@mui/material/styles/zIndex";

const Application = () => {
    const bak = {
        width: "32px",

        position: "absolute",
        top: "100%",
        left: "0",
        transform: "skewY(20deg)",
        backgroundColor: "black",
        zIndex: "-1",


    };
    const cards = {
        position: "relative",
        top: "35px",
        zIndex: "1"
    };
    // const back={
    //     backgroundColor:"#a100ff",
    //     border:"2px solid red",
    //     width:"64px",
    //     height:"32px",
    //     position:"absolute",
    //     top:"-8px",
    //     right:"-8px",
    //    zIndex:"-1"

    // }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="xl" >
            <Grid container sx={{ marginTop: "3%" }}>
                <Grid item xs={12} sm={6} md={4} sx={{ marginRight: "1%" }}>
                    <h1 className="head1">Future-focused applications</h1>
                    <p className="newParagraph">Make technology the foundational value to drive innovation and growth at an accelerated pace.</p>
                </Grid >
                <Grid item>
                    <img src={f} />
                </Grid >
            </Grid>

            <Grid sx={{ backgroundColor: "#f5f5f5", marginTop: "2%", padding: "2%", borderBottom: "solid 3px black" }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <h3 className="head3" >Applications driven by innovation</h3>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ marginRight: "5%" }}>
                        <p className="newParagraph">Complex application landscapes-composed of legacy systems, digital systems, and SaaS-consume too many IT resources and inhibit growth for most companies.</p>
                        <p className="newParagraph">With our application services, you can redesign your application landscape quickly and efficiently, while constantly innovating to realize business value.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ maxWidth: "75%" }}>
                        <div style={{ borderStyle: "solid", borderColor: "#a100ff", borderWidth: "0px 0px 0px 5px", borderRadius: "0" }}>
                            <div style={{ borderStyle: "solid", borderWidth: "2px", borderColor: "gray", padding: "20px" }}>
                                <h3>"The core of your applications should be innovation"</h3>
                                <blockquote>
                                    — Eshwar Sata, Director @moduluspi
                                </blockquote>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid sx={{ marginTop: "3%" }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <h1>End-to-end application services</h1>
                </Grid>
                <Grid item xs={12} sm={6} >
                    <p className="newParagraph">Every aspect of the application lifecycle is covered by us, from the creation of new applications to their modernization, management, and maintenance.</p>
                </Grid>
                <Grid container columnSpacing={12}>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={r} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2>Agile transform</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Drive disruption by applying Lean principles to achieve substantial performance improvements, accelerating business change.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <img src={c} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2>Application management</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Reimagine application management to meet rising complexity and create business value.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={cl} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2>Application modernization</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Rapidly modernize applications with agile migration and refactoring automation to unlock the full value of the cloud continuum.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={n} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2>Architecture</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Plan and deliver a foundational technology architecture to maximize scalability and performance.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={auto} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2 >Automation</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Improve speed, quality and customer experience through the power of cloud, data and AI.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={scale} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2>DevOps</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Streamline IT by bringing business, development and operations teams together and applying automated processes.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={sec} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2 >Quality Engineering</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Achieve better experiences, faster responses, greater insights and lower risks with Accenture Quality Engineering Services.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                        <img src={code} style={cards}></img>
                        <div style={{ boxShadow: "5px 5px gray", backgroundColor: "#f5f5f5", height: "60%", padding: "5%", position: "relative" }}>
                            <h2 >Application transform</h2>
                            <p className="newParagraph" style={{ marginBottom: "100px" }}>Harness the power of cloud and fuel innovation to create fresh sources of business value grounded in industry.</p>
                            <h4 style={{ position: "absolute", bottom: "0" }}><a href="">Learn More</a></h4>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%", marginTop: "2%", borderBottom: "solid 3px black" }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <h1>Intelligent platforms</h1>
                </Grid >
                <Grid item xs={12} sm={6} >
                    <p className="newParagraph">We combine the power of leading platforms—SAP, Oracle, Salesforce, Workday and more—with our intelligence, innovation and industry capabilities to drive large-scale, platform-enabled transformation.</p>
                </Grid>

                <Grid container >
                    <Grid item xs={12} sm={4} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h2>SAP</h2>
                        <p className="newParagraph">We partner with Oracle to help you innovate at scale through the entire Oracle solutions lifecycle <h4><a href="">Learn more</a></h4></p>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h2>Oracle</h2>
                        <p className="newParagraph">We partner with Oracle to help you innovate at scale through the entire Oracle solutions lifecycle <h4><a href="">Learn more</a></h4></p>

                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h2 >Salesforce</h2>
                        <p className="newParagraph">We partner with Oracle to help you innovate at scale through the entire Oracle solutions lifecycle <h4><a href="">Learn more</a></h4></p>

                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ backgroundColor: "white", padding: "2%", margin: "1%" }}>
                        <h2 >Workday</h2>
                        <p className="newParagraph">We partner with Oracle to help you innovate at scale through the entire Oracle solutions lifecycle <h4><a href="">Learn more</a></h4></p>
                    </Grid>
                </Grid>

            </Grid>

            <Grid sx={{ marginTop: "3%" }}>
                <Grid item xs={12}>
                    <h1>What we Think</h1>
                </Grid>
                <Grid>
                    <Grid container sx={{ marginBottom: "1%" }}>
                        <Grid item sx={{ marginRight: "5%" }}>
                            <img src={wwt2} style={{ width: "140px", height: "140px" }}></img>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Technology innovation</h4>
                            <h3>Technology Vision 2023: When Atoms meet Bits</h3>
                            <p className="newParagraph">The foundations of our new reality will be built by fusing the physical and digital worlds.</p>
                        </Grid>

                    </Grid>
                    <Grid container>
                        <Grid item sx={{ marginRight: "5%" }}>
                            <img src={wwt} style={{ width: "140px", height: "140px" }}></img>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>Technology</h4>
                            <h3>Make the leap, take the lead</h3>
                            <p className="newParagraph">Tech strategies for innovation and growth.</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{marginTop: "3%"}}>
                <Grid item xs={12} sm={6}>
                    <h1>Capabilities</h1>
                    <p className="newParagraph">
                        Transform applications to outperform in a cloud-first, mobile-first world while modernizing legacy enterprise applications to accelerate to the pace of business.
                    </p>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Moduluspi myWizard®</h2>
                        <p className="newParagraph">Develop, implement and measure an enterprise automation strategy with Moduluspi myWizard®.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Future Talent Platform</h2>
                        <p className="newParagraph">Empower your workforce to reskill and continuously learn with the Accenture Future Talent Platform.</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>
                        <h2>Technology innovation</h2>
                        <p className="newParagraph">Creating lasting value across the enterprise with technology innovation.</p>
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    )
}
export default Application;