import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AOS from 'aos';
import { Link } from 'react-router-dom'
import "aos/dist/aos.css";
import {
	Container,
	Fade,
	Grid,
	Typography,
	Card,
	CardContent,
} from '@material-ui/core';

import Animated from '../Animated.svg';

import Product from '../assets/images/product.png';
import Analytics from '../assets/images/analytics.png';
import Cloud from '../assets/images/cloud.png';
import Database from '../assets/images/database.png';
import DataGov from '../assets/images/datagov.png';
import Management from '../assets/images/management.png';
import Phone from '../assets/images/phone.png';
import Server from '../assets/images/server.png';
import Service from '../assets/images/services.png';
import Virtual from '../assets/images/virtual.png';

const useStyles = makeStyles((theme) => ({
	root: {},
	wrapper: {},
	section_one: {
		padding: '150px 0px 150px 0px',
		clipPath: 'polygon(0 0, 100% 12%, 100% 100%, 0 88%)',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		[theme.breakpoints.down('md')]: {
			clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
		},
		[theme.breakpoints.down('xs')]: {
			clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
		},
	},
	section_two: {
		padding: '150px 0px',
		background: theme.palette.primary.main,
		color: 'white',
		clipPath: 'polygon(0 0, 100% 12%, 100% 100%, 0 88%)',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		[theme.breakpoints.down('md')]: {
			clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
		},
		[theme.breakpoints.down('xs')]: {
			clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
		},
	},
	section_three: {
		padding: '150px 0px',
		clipPath: 'polygon(0 0, 100% 12%, 100% 100%, 0 88%)',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		[theme.breakpoints.down('md')]: {
			clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
		},
		[theme.breakpoints.down('xs')]: {
			clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
		},
	},
	paper: {
		zIndex: 1,
		position: 'relative',
		margin: theme.spacing(1),
	},

	card_type_one: {
		borderRadius: '0px',
		'&:hover': {
			transform: 'scale(1.05)',
		},
		transition: 'all .2s ease-in-out',
		background: '#4527A0',
		color: 'white',
		height: '320px',
		width: 'auto',
		maxWidth: '320px',
	},
	card_type_two: {
		borderRadius: '0px',
		'&:hover': {
			transform: 'scale(1.05)',
		},
		transition: 'all .2s ease-in-out',
		background: theme.palette.primary.main,
		color: 'white',
		// height: '160px',
		width: 'auto',
		maxWidth: '300px',
	},
	card_type_two_body: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	svg: {
		marginTop: '-100px',
		[theme.breakpoints.up('sm')]: {
			marginTop: '-250px',
		},
	},
	visual_subtitle: {
		fontFamily: 'Poppins',
		fontWeight: 'bold',
		fontSize: '28px',

		textAlign: 'center',
		background: 'linear-gradient(90deg, #fc466b 0%, #3f5efb 100%)',
		backgroundSize: '100%',
		backgroundRepeat: 'repeat',
		/* Use the text as a mask for the background. */
		/* This will show the gradient as a text color rather than element bg. */
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',
		color: 'transparent',
		marginBottom: '50px',
		padding: '0',
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2em',
		},
		'&:hover': {
			textFillColor: 'transparent',
			textStrokeColor: 'currentColor',
			textStrokeWidth: '0.5px',
		},
	},
}));

const cardData = [
	{
		heading: 'Enterprise Application Service',
		content:
			'Hitech solutions designed to help organizations achieve their strategic objectives and run business better and cost effective.',
	},
	{
		heading: 'Product Development',
		content: 'Building products on supply chain and CRM domains.',
	},
	{
		heading: 'Cloud Technologies',
		content: 'Helping enterprises in cloud solutions and SAAS platforms.',
	},
	{
		heading: 'Mobility Platform',
		content:
			'Helps in enabling enterprises with hybrid and native mobile platforms for transactional and analytical apps.',
	},
];

const Services = () => {
	const classes = useStyles();
	const [mount, setMount] = React.useState(false);
	// const [mountTwo, setMountTwo] = React.useState(false);

	useEffect(() => {
		handleMount();
		// handleMountTwo();
		return () => { };
	}, []);

	const handleMount = () => {
		setMount(true);
	};
	useEffect(() => {
		AOS.init({
			duration: 2000
		});
	}, []);

	// const handleMountTwo = () => {
	// 	setMountTwo(true);
	// };

	return (
		<div className={classes.root}>
			<Fade direction="up" in={mount} timeout={1000} mountOnEnter unmountOnExit>
				<div className={classes.wrapper}>
					<div className={classes.section_one}>
						<Grid container align="center">
							<Container>
								<Grid item xs={10} sm={10} md={10} lg={8}>
									<h1 className={classes.visual_subtitle} data-aos="fade-up">Tailored for growing businesses</h1>
									<h3 style={{ color: 'grey' }}>
										Everything you need to run fast and scale faster.
										<br /> Develop, manage, and scale your applications with
										ModulusPI.
									</h3>
									{/* <object
										type="image/svg+xml"
										data={Animated}
										className={classes.svg}
									>
										svg-animation
									</object> */}
								</Grid>
							</Container>
						</Grid>
					</div>
					<div className={classes.section_two}>
						<Container align="center">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={3} lg={3}>
								<Link to="application" style={{textDecoration:"none"}}>	
									<Card className={classes.card_type_one} data-aos="fade-up">
										<CardContent className={classes.card_body}>
											<img src={Service} />
											<h2 >Enterprise Application Services</h2>
											<p className={classes.card_para}>
												Hitech solutions designed to help organizations achieve
												their strategic objectives and run business better and
												cost effective.
											</p>
										</CardContent>
									</Card>
								</Link>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
								<Link to="outsourcing" style={{textDecoration:"none"}}>	
									<Card className={classes.card_type_one} data-aos="fade-up">
										<CardContent className={classes.card_body}>
											<img src={Product} />
											<h2>Business Process Outsourcing</h2>
											<p className={classes.card_para}>
											In a constantly evolving world, business process services have the potential to revolutionize business performance by providing intelligent operations that generate sustainable value.

											</p>
										</CardContent>
									</Card>
								</Link>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
								<Link to="cloud" style={{textDecoration:"none"}}>	
									<Card className={classes.card_type_one} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={quality} /> */}
										<CardContent className={classes.card_body}>
											<img src={Cloud} />
											<h2> Cloud Technologies</h2>
											<p className={classes.card_para}>
												Helping enterprises in cloud solutions and SAAS
												platforms.
											</p>
										</CardContent>
									</Card>
								</Link>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
								<Link to="financeconsulting" style={{textDecoration:"none"}}>	
									<Card className={classes.card_type_one} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={quality} /> */}
										<CardContent className={classes.card_body}>
											<img src={Phone} />
											<h2>Finance Consulting</h2>
											<p className={classes.card_para}>
											Assisting leaders in finance and risk management to navigate through disruption and enhance enterprise value.
											</p>
										</CardContent>
									</Card>
								</Link>
								</Grid>
							</Grid>
						</Container>
					</div>
					<div className={classes.section_three}>
						<Container align="center">
							<Grid container>
								<Container>
									<Grid
										item
										xs={10}
										sm={10}
										md={10}
										lg={6}
										style={{ marginBottom: '30px' }}
									>
										<h1 className={classes.visual_subtitle} data-aos="fade-up"> Solutions for industries solving complex problems</h1>
									</Grid>
								</Container>
							</Grid>

							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
								<Link to="LifeSciences" style={{textDecoration:"none"}}>	
									<Card className={classes.card_type_two} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={vision} /> */}
										<CardContent className={classes.card_type_two_body}>
											<img src={Database} />
											<h3>
												Life
												<br />
												Sciences
											</h3>
										</CardContent>
									</Card>
									</Link>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
								<Link to="NaturalResources" style={{textDecoration:"none"}}>
									<Card className={classes.card_type_two} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={values} /> */}
										<CardContent className={classes.card_type_two_body}>
											<img src={Server} />
											<h3>
												Natural
												<br /> Resources
											</h3>
										</CardContent>
									</Card>
									</Link>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Card className={classes.card_type_two} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={quality} /> */}
										<CardContent className={classes.card_type_two_body}>
											<img src={Management} />
											<h3>
												Application
												<br /> management
											</h3>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Card className={classes.card_type_two} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={quality} /> */}
										<CardContent className={classes.card_type_two_body}>
											<img src={Virtual} />
											<h3>
												Virtual and
												<br /> Augmented reality
											</h3>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Card className={classes.card_type_two} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={quality} /> */}
										<CardContent className={classes.card_type_two_body}>
											<img src={Analytics} />
											<h3>
												Data
												<br /> Analytics
											</h3>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Card className={classes.card_type_two} data-aos="fade-up">
										{/* <CardMedia className={classes.card_media} image={quality} /> */}
										<CardContent className={classes.card_type_two_body}>
											<img src={DataGov} />
											<h3>
												Data
												<br /> Governance
											</h3>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</Fade>
		</div>
	);
};
export default Services;
