import React, { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	CssBaseline,
	Drawer,
	AppBar,
	Toolbar,
	List,
	Hidden,
	Button,
	Typography,
	IconButton,
	ListItem,
	ListItemText,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';

import titleBlack from '../assets/logo/titleBlack.jpg'

const drawerWidth = '100vw';

const useStyles = makeStyles((theme) => ({
	root: {},
	appBar: {
		display: 'flex',
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflow: 'hidden',
		// glassmorphism
		background: 'white',
		// background: 'rgba( 0, 0, 0, 0.0 )',
		// backdropFilter: 'blur( 10px )',
	},

	title: {
		flexGrow: 1,
		textDecoration: 'none',
		margin: '0px',
		padding: '5px 0px 0px 0px'

	},
	navLink: {
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
		padding: '0 25px',
		// fontSize:'11px',
		textTransform: 'capitalize',
		fontWeight: '100',
		color: 'grey',
		'&:hover': {
			textFillColor: 'black',
		},
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		padding: theme.spacing(4),
		background: theme.palette.secondary.main,
		color: 'white'
	},
	drawerButton: {
		textAlign: 'center',
		// clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
	},
	drawerFooter: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

var { REACT_APP_MREALTOR_PUBLIC_URL } = process.env

const Navbar = forwardRef((props, ref) => {

	console.log("mrealtor url: ", process.env);

	useImperativeHandle(ref, () => ({
		handleDrawerClose,
	}));
	const classes = useStyles();
	const theme = useTheme();

	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={classes.appBar} color="transparent">
				<Toolbar>
					<Typography

						noWrap
						className={classes.title}
						component={Link}
						to="/"
					>
						<img src={titleBlack} height="30" width="auto" alt="" />
					</Typography>
					<Hidden smDown>
						{/* <a href={REACT_APP_MREALTOR_PUBLIC_URL} className="anchortag">m-realtor</a> */}
						<Button
							className={classes.navLink}
							href="#text-buttons"
							color="inherit"
							size="small"
							component={Link}
							to="/about"
						>
							<h3>About</h3>
						</Button>
						<Button
							className={classes.navLink}
							href="#text-buttons"
							color="inherit"
							size="small"
							component={Link}
							to="/services"
						>
							<h3>Services</h3>
						</Button>
						<Button
							className={classes.navLink}
							href="#text-buttons"
							color="inherit"
							size="small"
							component={Link}
							to="/careers"
						>
							<h3>Careers</h3>
						</Button>
						<Button
							className={classes.navLink}
							href="#text-buttons"
							color="inherit"
							size="small"
							component={Link}
							to="/contact"
						>
							<h3>Contact</h3>
						</Button>
					</Hidden>
					<Hidden mdUp>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="end"
							onClick={handleDrawerOpen}
							className={clsx(open && classes.hide)}
						>
							<MenuIcon />
						</IconButton>
					</Hidden>
				</Toolbar>
			</AppBar>

			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="top"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<List>
					<ListItem
						button
						onClick={handleDrawerClose}
						className={classes.drawerButton}
						component={Link}
						to="/about"
					>
						<h3>About</h3>
					</ListItem>
					<ListItem
						button
						onClick={handleDrawerClose}
						className={classes.drawerButton}
						component={Link}
						to="/services"
					>
						<h3>Services</h3>
					</ListItem>
					<ListItem
						button
						onClick={handleDrawerClose}
						className={classes.drawerButton}
						component={Link}
						to="/careers"
					>
						<h3>Career</h3>
					</ListItem>
					<ListItem
						button
						onClick={handleDrawerClose}
						className={classes.drawerButton}
						component={Link}
						to="/contact"
					>
						<h3>Contact</h3>
					</ListItem>
				</List>
				{/* <div className={classes.drawerFooter}>
					<IconButton onClick={handleDrawerClose}>
						<CloseIcon />
					</IconButton>
				</div> */}
			</Drawer>
		</div>
	);
});
export default Navbar;
