import image2 from "../images/banking&finance.jpg";
import sec1 from "../images/security1.jpg";
import React from "react";
import "./security.css";
import { Row, Col } from "react-bootstrap";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useEffect } from "react";

const EventTemplate = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
    
    return (
        <Container maxWidth="xl">
            <div style={{ marginTop: "3%", position: "relative" }}>
                <div className="newFullPageImage">
                    <img src={sec1} alt="Full Page Image" />
                </div>
                <div className="newTextOverLay ">
                    <h1>Security</h1>
                    <p>
                        Wherever your business goes, whoever it works with, you need cybersecurity that covers it all.
                    </p>
                </div>
            </div>

            <Grid sx={{ backgroundColor: "#f5f5f5", marginTop: "3%", padding: "2%", borderBottom: "solid 3px black" }}>

                <Grid item xs={12} sm={6}>
                    <h1>
                        Harnessing cybersecurity for a competitive edge amidst an unpredictable world.
                    </h1>
                </Grid>

                <Grid item xs={12} sm={10}>
                    <p className="newParagraph">
                        Business leaders are deeply concerned about the economy. C-suite executives acknowledge that the survival of their organizations relies on their ability to protect systems and information. They must prioritize resilience by mitigating risks, strategically allocating resources and investments, and establishing clear accountability structures.
                    </p>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "3%" }}>
                    <h1 >
                        How to become a cyber champion
                    </h1>
                </Grid>

                <Grid item xs={12} sm={10}>
                    <p className="newParagraph">
                        The research on Cybersecurity Resilience provides valuable insights from our annual survey, which involved 4,744 participants from around the world. The report delves into the degree to which organizations prioritize security, the comprehensiveness of their security plans, and the effectiveness of their security investments. Additionally, it investigates the impact of aligning cybersecurity strategy with overall business strategy on achieving cyber resilience. The study identifies four levels of cyber resilience, with the Cyber Champions emerging as the frontrunners. These organizations not only excel in cyber resilience but also align their cybersecurity efforts with their business strategy to achieve superior business outcomes.            </p>
                </Grid>

            </Grid>


            <Grid container sx={{ marginTop: "3%", border: "1px solid #e0e0e0" }}>
                <Grid item xs sx={{ padding: "2%" }}>
                    <h2 >Our Cyber fusion centers</h2>
                    <ul className="ul1">
                        <li>Houston Cyber Fusion Center</li>
                        Modulus Pi's purpose-built, one-of-a-kind Cyber Fusion Center for operational technology (OT) security and industrial control systems (ICS).
                        <li>Global Cyber Fusion Centers</li>
                        A collective of global security professionals, leading data scientists, renowned academic researchers as well as technology and alliance partners.
                        <li>D.C. Cyber Fusion Center</li>
                        The Cyber Fusion Center in Washington, D.C., combines threat intelligence, adversary simulations, incident response and cybersecurity R&D.
                        Helping banks navigate compliance; cyber risk; fraud & financial crime; and analytics & technology.
                    </ul>
                </Grid>
                <Divider orientation="vertical" flexItem>
                </Divider>
                <Grid item xs sx={{ padding: "2%" }}>
                    <h2 >Future of Cyber</h2>
                    <ul className="ul1">
                        <p>Modulus Pi addresses how traditional identity and access management (IAM) and digital identity are complementary tools. Both help organizations develop trust, ensure privacy, increase security and provide an excellent user experience. As the world evolves to expand digital experiences and blend them with authentic in-person interactions, the importance of enhancing existing IAM roadmaps and taking advantage of digital identity capabilities increases.<br></br></p><p>Modulus Pi partners with Cyber Million, an initiative to help recruit entry-level candidates with cyber skills on the Immersive Labs platform.

                        </p>
                    </ul>
                </Grid>
            </Grid>
        </Container>
    );
};

export default EventTemplate;
