import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Fade,
  Grid,
  Container,
  Hidden,
  Button,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import BackgroundSlider from 'react-background-slider';
import AOS from 'aos';
import "aos/dist/aos.css";
import * as Typed from "typed.js";

import { Link } from 'react-router-dom'
import HomeFiber from './HomeFiber'
import TitleAnimation from '../components/TitleAnimation'

// logo imports

import android from '../assets/logo/android.png'
import angular from '../assets/logo/angular.png'
import aws from '../assets/logo/aws.png'
import azure from '../assets/logo/azure.png'
import dotnet from '../assets/logo/dotnet.png'
import informatica from '../assets/logo/informatica.png'
import java from '../assets/logo/java.png'
import oracle from '../assets/logo/oracle.png'
import react from '../assets/logo/react.png'
import salesforce from '../assets/logo/salesforce.png'
import sap from '../assets/logo/sap.png'
import swift from '../assets/logo/swift.png'
import thermo from '../assets/logo/thermo.png'
import unity from '../assets/logo/unity.png'
import unreal from '../assets/logo/unreal.png'

// import copper from "../assets/logo/copper.png";
import mold from '../assets/logo/mold.png'
import planet from '../assets/logo/planet.png'
import save from '../assets/logo/save.png'
import transpotation from '../assets/logo/transpotation.png'
import well from '../assets/logo/well.png'
import copper from '../assets/logo/copper.jpg'


import logoMain from '../assets/logo/logoMain.png'
import logoWhite from '../assets/logo/logoWhite.png'

import Product from '../assets/images/product.png';
import Logistics from '../assets/images/logistics.png';
import Cloud from '../assets/images/cloud.png';
import ProductComapanies from '../assets/images/product_companies.png';
import Augmented from '../assets/images/augmented.png';
import Banking from '../assets/images/banking.png';
import Phone from '../assets/images/phone.png';
import Healthcare from '../assets/images/healthcare.png';
import Service from '../assets/images/services.png';
import Ecommerce from '../assets/images/ecommerce.png';
import Hunger2 from '../assets/images/hunger2.png';




const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {},
  title_two: {
    fontSize: '40px',
    marginBottom: '50px',
  },
  section_one: {
    padding: theme.spacing(0, 0.5),
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginBottom: '-8vh'

  },
  section_two: {
    paddingTop: '50px',
    paddingBottom: '50px',
    // background: theme.palette.primary.main,
    // color: "white",
  },
  section_three: {
    paddingTop: '150px',
    paddingBottom: '250px',
    background: theme.palette.primary.main,
    color: 'white',
    clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
    // clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
    [theme.breakpoints.down('md')]: {
      clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
    },
    [theme.breakpoints.down('xs')]: {
      clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
    },

  },
  section_four: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  section_five: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  section_six: {
    paddingTop: '100px',
    paddingBottom: '150px',
    background: theme.palette.primary.main,
    color: 'white',
    clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
    // clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
    [theme.breakpoints.down('md')]: {
      clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 90%)',
    },
    [theme.breakpoints.down('xs')]: {
      clipPath: 'polygon(0 0, 100% 5%, 100% 100%, 0 95%)',
    },

  },

  visual_title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '19rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    background: 'linear-gradient(90deg, #fc466b 0%, #3f5efb 100%)',
    backgroundSize: '100%',
    backgroundRepeat: 'repeat',
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    [theme.breakpoints.down('md')]: {
      fontSize: '14em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '9em',
    },
    '&:hover': {
      textFillColor: 'transparent',
      textStrokeColor: 'currentColor',
      textStrokeWidth: '.015em',
    },
  },
  visual_subtitle: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '28px',

    textAlign: 'center',
    background: 'linear-gradient(90deg, #fc466b 0%, #3f5efb 100%)',
    backgroundSize: '100%',
    backgroundRepeat: 'repeat',
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    marginBottom: '50px',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      fontSize: '2em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2em',
    },
    '&:hover': {
      textFillColor: 'transparent',
      textStrokeColor: 'currentColor',
      textStrokeWidth: '0.5px',
    },
  },

  section_xr: {
    // background: '#651FFF',
    // background: 'black',
    paddingTop: '50px',
    paddingBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#FFF',

  },
  textAnimation_xr: {
    fontFamily: 'Montserrat',
    fontSize: '300px',
    fontWeight: 400,
    margin: '10px 0',
    backgroundImage:
      ' url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    backgroundSize: 'cover',
    letterSpacing: '1px',
    lineHeight: '.75',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '200px',
    },
    color: 'transparent',
  },
  button_xr: {
    marginTop: theme.spacing(4),
    color: 'grey',
    borderRadius: '0px',
    clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
    paddingRight: '50px',
    paddingLeft: '50px',
    '&:hover': {
      textFillColor: 'black',
    },
  },
  paper: {
    boxShadow: 'none',
  },
  //   unreal_logo: {
  //     height: "100px",
  //     [theme.breakpoints.down("xs")]: {
  //       height: "50px",
  //     },
  //   },
  //   unity_logo: {
  //     height: "50px",
  //     [theme.breakpoints.down("xs")]: {
  //       height: "25px",
  //     },
  //   },
  card_type_one: {
    borderRadius: '0px',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    transition: 'all .2s ease-in-out',
    background: '#4527A0',
    color: 'white',
    height: '320px',
    width: 'auto',
    maxWidth: '320px',
  },
  card_type_two: {
    boxShadow: 'none',
    borderRadius: '0px',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    transition: 'all .2s ease-in-out',
    // background: theme.palette.primary.main,
    color: 'grey',
    // height: '160px',
    width: 'auto',
    maxWidth: '300px',
  },
  card_type_two_body: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  image_card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }
}))
const cardData = [
  {
    heading: 'Enterprise Application Service',
    content:
      'Hitech solutions designed to help organizations achieve their strategic objectives and run business better and cost effective.',
  },
  {
    heading: 'Product Development',
    content: 'Building products on supply chain and CRM domains.',
  },
  {
    heading: 'Cloud Technologies',
    content: 'Helping enterprises in cloud solutions and SAAS platforms.',
  },
  {
    heading: 'Mobility Platform',
    content:
      'Helps in enabling enterprises with hybrid and native mobile platforms for transactional and analytical apps.',
  },
];

const Home = () => {
  const typedJSRef = useRef(null);
  const classes = useStyles()
  const [mount, setMount] = React.useState(false)
  // const [mountTwo, setMountTwo] = React.useState(false);

  useEffect(() => {
    handleMount()
    // handleMountTwo();
    return () => { }
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  const handleMount = () => {
    setMount(true)
  }

  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["design.", "create.", "deliver."],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  // const handleMountTwo = () => {
  //   setMountTwo(true);
  // };

  return (
    <div className={classes.root}>
      {/* <Fade direction="up" in={mount} timeout={5000} mountOnEnter unmountOnExit> */}
      <div className={classes.wrapper}>
        <div className={classes.section_one} >

          <Container maxWidth='sm' align="center" disableGutters="true" >
            {/* <HomeFiber /> */}
            {/* <img src={logoWhite} height="300" width="auto" alt="" /> */}

            {/* <video id="background-video" loop autoplay>
              <source src={Cover} type="video/mp4" />
              <source src={Cover} type="video/ogg" />
            </video> */}

            {/* <BackgroundSlider
              images={[Cover4, Cover5]}
              duration={15} transition={1} /> */}
            <div>
              <img src={logoMain} height="250" width="auto" alt="" />
              <p data-aos="fade-up" className='gradient-text' style={{ color: 'grey' }}>new era of digital<br /> innovation  is here.<br />We <span style={{ color: 'black' }} ref={typedJSRef} /></p>
            </div>
          </Container>
        </div>

        <div className={classes.section_two}>
          <Container align="center" disableGutters="true">
            <div data-aos="fade-up">
              <h1 className={classes.visual_subtitle}>We will build your applications with
                top tech skills.</h1>
            </div>
          </Container>
          <div className="slider">
            <div className="slide-track">
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={android} height="45" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={azure} height="80" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={angular} height="50" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={aws} height="50" width="auto" alt="" />
              </div>

              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={informatica} height="40" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={java} height="80" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={unreal} height="60" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={dotnet} height="50" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={oracle} height="15" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={react} height="50" width="auto" alt="" />
              </div>

              <div
                classNames="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0px 50px',
                }}
              >
                <img src={unity} height="35" alt="" />
              </div>

              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={salesforce} height="45" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={swift} height="40" width="auto" alt="" />
              </div>
              <div
                className="slide"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={sap} height="50" width="auto" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.section_three}>
          <Container align="center" disableGutters="true">
            <div data-aos="fade-up">
              <h1 style={{ marginBottom: '50px' }}> Solutions for industries solving <br />complex problems </h1>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Link to="application" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_one} data-aos="fade-up">
                  <CardContent className={classes.card_body}>
                    <img src={Service} />
                    <h2>Enterprise Application Services</h2>
                    <p className={classes.card_para}>
                      Hitech solutions designed to help organizations achieve
                      their strategic objectives and run business better and
                      cost effective.
                    </p>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Link to="outsourcing" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_one} data-aos="fade-up">
                  <CardContent className={classes.card_body}>
                    <img src={Product} />
                    <h2>Business Process Outsourcing</h2>
                    <p className={classes.card_para}>
                    In a constantly evolving world, business process services have the potential to revolutionize business performance by providing intelligent operations that generate sustainable value.
                    </p>
                  </CardContent>
                </Card>
              </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Link to="cloud" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_one} data-aos="fade-up">
                  {/* <CardMedia className={classes.card_media} image={quality} /> */}
                  <CardContent className={classes.card_body}>
                    <img src={Cloud} />
                    <h2> Cloud Technologies</h2>
                    <p className={classes.card_para}>
                      Helping enterprises in cloud solutions and SAAS
                      platforms.
                    </p>
                  </CardContent>
                </Card></Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Link to ="financeconsulting" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_one} data-aos="fade-up">
                  {/* <CardMedia className={classes.card_media} image={quality} /> */}
                  <CardContent className={classes.card_body}>
                    <img src={Phone} />
                    <h2>Finance Consulting</h2>
                    <p className={classes.card_para}>
                    Assisting leaders in finance and risk management to navigate through disruption and enhance enterprise value.
                    </p>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
          
            </Grid>
          </Container>
        </div>
        <div className={classes.section_four}>
          <Container align="center">
            <Grid container>
              <Container>
                <Grid
                  item
                  xs={10}
                  sm={10}
                  md={10}
                  lg={6}
                  style={{ marginBottom: '30px' }}
                >
                  <h1 data-aos="fade-up" className={classes.visual_subtitle}> Our team is specialized in a wide array of industry verticals</h1>
                </Grid>
              </Container>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Link to="Security" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_two}>
                  {/* <CardMedia className={classes.card_media} image={vision} /> */}
                  <CardContent className={classes.card_type_two_body}>
                    <img src={ProductComapanies} />
                    <h3>
                      Security
                    </h3>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <Link to="health" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_two}>
                  {/* <CardMedia className={classes.card_media} image={values} /> */}
                  <CardContent className={classes.card_type_two_body}>
                    <img src={Healthcare} />
                    <h3>
                      Healthcare
                    </h3>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Link to="banking_and_finance" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_two}>
                  {/* <CardMedia className={classes.card_media} image={quality} /> */}
                  <CardContent className={classes.card_type_two_body}>
                    <img src={Banking} />
                    <h3>
                      Banking &#38;<br />
                      Finance
                    </h3>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <Link to="Retail_Ecom" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_two}>
                  {/* <CardMedia className={classes.card_media} image={quality} /> */}
                  <CardContent className={classes.card_type_two_body}>
                    <img src={Ecommerce} />
                    <h3>
                      Retail &#38;<br />
                      E-Commerce
                    </h3>
                  </CardContent>
                </Card>
              </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <Link to="Utilities" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_two}>
                  {/* <CardMedia className={classes.card_media} image={quality} /> */}
                  <CardContent className={classes.card_type_two_body}>
                    <img src={Logistics} />
                    <h3>
                      Utilities
                    </h3>
                  </CardContent>
                </Card>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
              <Link to="Public_sector" style={{textDecoration:"none"}}>
                <Card className={classes.card_type_two}>
                  {/* <CardMedia className={classes.card_media} image={quality} /> */}
                  <CardContent className={classes.card_type_two_body}>
                    <img src={Augmented} />
                    <h3>
                      Public
                      <br />Sector
                    </h3>
                  </CardContent>
                </Card>
              </Link>
              </Grid>
            </Grid>
          </Container>
        </div>

        {/* <div className={classes.section_xr}>
            <Typography className={classes.textAnimation_xr}>XR</Typography>
            <Button
              className={classes.button_xr}
              color="inherit"
              component={Link}
              to="xr"
            >
              dive in
            </Button>
          </div> */}
        {/* <div >


          <div style={{ display: 'flex' }}>
            <img src={Hunger} height="auto" width="auto" alt="" data-aos="fade-up" />
            <img src={Hunger2} height="auto" width="auto" alt="" data-aos="fade-up" />
            <img src={Hunger3} height="auto" width="auto" alt="" data-aos="fade-up" />
          </div>

        </div> */}
      </div >
      <div className={classes.section_six}>
        <Container align="center" maxWidth='sm' >
          {/* <h1 style={{ margin: '50px 0px' }}>Philanthropy</h1> */}
          <h1 data-aos="fade-up" style={{ margin: '50px 0px' }}>Philanthropy</h1>
          <img src={Hunger2} height='100%' width='100%' object-fit='contain' />
          <h3 style={{ margin: '16px 0px' }}>
            Fulfilling our Mission depends on developing bright young talent to push the boundaries of our existing technologies and innovate for the future. That’s why our philanthropic efforts – focus on providing financial, in-kind and volunteer support to promote Technology education, and inspiring students to pursue careers in Technology.
          </h3>



        </Container>
      </div>
      <div className={classes.section_five}>
        <Container align="center">
          <h1 data-aos="fade-up" className={classes.visual_subtitle}>Our clients include Global Brands, Silicon Valley Founders and many more.</h1>
        </Container>
        <div className="slider_two">
          <div className="slide-track">
            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={well} height="45" width="auto" alt="" />
            </div>
            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={thermo} height="40" width="auto" alt="" />
            </div>
            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={save} height="50" width="auto" alt="" />
            </div>
            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={transpotation} height="50" width="auto" alt="" />
            </div>

            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={mold} height="150" width="auto" alt="" />
            </div>

            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={copper} height="50" width="auto" alt="" />
            </div>
            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={planet} height="200" width="auto" alt="" />
            </div>
            {/* <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={well} height="45" width="auto" alt="" />
            </div>
            <div
              className="slide"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={thermo} height="40" width="auto" alt="" />
            </div> */}
          </div>
        </div>
      </div>
      {/* </Fade > */}

    </div >
  )
}
export default Home
