import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Card from "react-bootstrap/card";
import cloudservice from "../images/cloud_service.png";
import c2 from "../images/c2.webp";
import c3 from "../images/c3.jpg";
import partner from "../images/eco.png";
import { useEffect } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import "./cloud.css"
const Cloud = () => {
    const border = {
        height: "200px",

        borderStyle: "solid",
        borderWidth: "0 4px 0 0",
        borderColor: "red",


    };
    const cards = {
        borderRadius: "0",
        width: "350px",
        marginBottom: "15px",
        backgroundColor: "#f2f2f2"


    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="xl">

            <Grid container sx={{ marginTop: "3%" }}>
                <Grid item xs={12} sm={6} md={4} sx={{ marginRight: "1%" }}>
                    <h1 className="head1">Cloud Solutions</h1>
                    <p className="newParagraph">Accelerate the value delivery by adopting a Cloud First approach.</p>
                </Grid>
                <Grid item>
                    <img src={cloudservice} />
                </Grid>
            </Grid>

            <Grid sx={{ marginTop: "3%" }}>
                <Grid>
                    <h1>Competencies</h1>
                    <p className="newParagraph">Getting the most out of cloud computing requires more than just technology, so we also provide solutions to address workforce and cultural issues.</p>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Cloud-stored Data & AI</h2>
                        <p className="newParagraph">
                            Provide businesses with data and AI insights and intelligence based on cloud industry-specific data models.
                        </p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Cloud architecture</h2>
                        <p className="newParagraph"> In the future, IT infrastructures could monitor themselves, predict future business needs, and protect and heal themselves.</p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Application modernization</h2>
                        <p className="newParagraph"> Bring industry-based innovation to the cloud to create fresh business value.</p>

                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Edge networking</h2>
                        <p className="newParagraph"> Businesses can reimagine experiences for people, purpose, and profitability with edge computing, powered by the cloud.</p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Network</h2>
                        <p className="newParagraph">Establish a robust network infrastructure to accelerate digital transformation and improve business performance.</p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Cloud privacy</h2>
                        <p className="newParagraph">Keeping your IT estate secure is easy with our cloud security services.</p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Application outsourcing services</h2>
                        <p className="newParagraph">Make proactive innovations part of your application portfolio to future-proof it.</p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Sustainable computing & technologies</h2>
                        <p className="newParagraph">Sustainability is enabled in large part by digital technology.</p>

                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Cloud architecture planning</h2>
                        <p className="newParagraph">Technology Strategy & Advisory helps companies architect and realize exceptional business value.</p>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ border: "1px solid #e0e0e0", padding: "1rem", margin: "1%" }}>

                        <h2>Cloud infrastructure management</h2>
                        <p className="newParagraph">
                            Adopt a new, modern approach to IT operations with cloud-based infrastructure.
                        </p>

                    </Grid>

                </Grid>
            </Grid>

            <Grid sx={{ backgroundColor: "#f5f5f5", padding: "2%", marginTop: "3%" }}>
                <Grid sx={{ display: "flex", backgroundColor: "white", padding: "2%", justifyContent: "center" }}>
                    <div style={{ marginRight: "5%" }}>
                        <div style={border}>
                        </div>
                    </div>
                    <Grid item xs={12} sm={6}>
                        <h2>Cloud computing is really a no-brainer for any start-up because it allows you to test your business plan very quickly for little money.  </h2>
                    </Grid>
                </Grid>
            </Grid>


            <Grid sx={{ marginTop: "3%" }}>
                <Grid item xs={12} sm={6}>
                    <h1>Strategic alliances</h1>
                    <p className="newParagraph">Our vast global network of market leaders and innovators enables us to extend our industry expertise and comprehensive cloud services, empowering our clients to overcome any challenge and expedite their path to achieving value.</p>
                </Grid>
            </Grid>

            <Grid sx={{ marginTop: "3%", backgroundColor: "#f5f5f5", padding: "2%", borderBottom: "solid 3px black" }}>
                <Grid item xs={12} sm={8}>
                    <h1>How We Work</h1>
                    <p className="newParagraph">At Cloud First, we possess expertise in delivering essential business solutions in the cloud that are critical for accomplishing your goals. Upon evaluating your objectives, we collaborate with you to determine the most appropriate cloud solution for your specific business requirements, enabling you to realize value rapidly.</p>                
                </Grid>
                <Grid sx={{display: "flex", justifyContent: "space-around"}}>
                        <div>
                            <h3>Matchless skills and expertise</h3>
                        </div>
                        <div>
                            <h3>Industry-specific cloud solutions</h3>
                        </div>
                </Grid>
            </Grid>
        </Container>
    )
}
export default Cloud;