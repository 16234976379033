import ps2 from "../images/ps2.png";
import ps from "../images/PUBLIC_SECTOR.png";
import React from "react";
import "./public_sector.css";
import { Row, Col } from "react-bootstrap";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useEffect } from "react";

const EventTemplate = () => {
  return (
    <Container maxWidth="xl">
      
      <div className="full-page-image3">
        <img src={ps} alt="Full Page Image3" />
      </div>

      <Grid sx={{ backgroundColor: "#f5f5f5", marginTop: "3%", marginBottom: "3%", padding: "2%", borderBottom: "solid 3px black"}}>
        <div>

          <div style={{display: "flex", justifyContent: "center"}}>
            <h1
              style={{ marginBottom: "3%", color: "grey" }}
            >
              Boost mission impact with AI, data, and CRM.
            </h1>
          </div>

          <Grid container sx={{display: "flex", justifyContent: "center"}}>
              <Grid item xs={12} sm={5} sx={{ backgroundColor: "#ebebeb", border: "4px solid #ccc", padding: "1rem", margin: "1%" }}>
                <h2 className="ph">Innovative</h2>
                <p className="para">
                  Get a secure platform that features easy point and click
                  development, powerful workflow, three seamless updates
                  annually, and customer-inspired enhancements. Plus
                  compatibility with mobile, social, and AI.
                </p>
              </Grid>

              <Grid item  xs={12} sm={5} sx={{ backgroundColor: "#ebebeb", border: "4px solid #ccc", padding: "1rem", margin: "1%" }}>
                <h2 className="ph">Fast and Easy</h2>
                <p className="para">
                  Get set up quickly with no hardware or software to install or
                  code to write. More than 150,000 customers have benefited from
                  our multitenant cloud, agile day-one deployment, and
                  scalability.
                </p>
            </Grid>

              <Grid item  xs={12} sm={5} sx={{ backgroundColor: "#ebebeb", border: "4px solid #ccc", padding: "1rem", margin: "1%" }}>
                <h2 className="ph">Open</h2>
                <p className="para">
                  Connect with any internal or external system from any device.
                  Access millions of developers and partners. And get AI built
                  right into the platform.
                </p>
              </Grid>

              <Grid item  xs={12} sm={5} sx={{ backgroundColor: "#ebebeb", border: "4px solid #ccc", padding: "1rem", margin: "1%" }}>
                <h2 className="ph">Trusted</h2>
                <p className="para">
                  Count on a platform that’s secure, transparent, and compliant.
                  We’ve continually earned the industry’s top compliance
                  standards, including in Australia ASD has awarded iRAP
                  Certification to Salesforce for specified cloud services.
                </p>
            </Grid>
          </Grid>
          
          <Grid sx={{marginTop: "3%"}}>

              <h1
                style={{ marginBottom: "3%" }}
              >
                See how the world’s #1 cloud helps government customers succeed.
              </h1>
              <p className="para">
                It’s no secret schools and governments are notoriously slow to
                migrate to new technologies. That is unless you’re the Texas
                Department of Information Resources (DIR) — a group of
                forward-thinking folks responsible for providing technology
                solutions and advice to help the state’s educational, local, and
                government entities fulfill their missions. The DIR is busy
                building easy-to-use, cost-effective apps with Salesforce. Now,
                125+ state agencies and thousands of local governments and
                schools can take advantage of the latest social, mobile, and
                cloud technologies to help the citizens of the Lone Star State
                succeed.
              </p>

              <p className="para">
                Salesforce App Cloud helps Texas rapidly develop cloud apps that
                can run on any mobile device, and is key to the organisation’s
                strategy for helping state agencies help its citizens.
              </p>

              <p className="para">
                “If you want state agencies to move to 21st century technology,
                you need to keep it simple to implement and use,” says Todd
                Kimbriel, Chief Operating Officer. “So we adopted a Salesforce
                first policy for our development team.”
              </p>

              <p className="para">
                The DIR originally began using Salesforce to manage procurement
                contracts so agencies from all over the state could take
                advantage of pre-negotiated deals for everything from desktop
                computers to monitors to professional services. Since that time
                the organisation has built 25+ new apps manage asset tracking,
                budgeting, help desks, portfolios, and more. Adding new apps
                with App Cloud is faster than with traditional platforms. “With
                Salesforce we can build apps in three weeks that would take at
                least 18 months with other solutions,” says Kimbriel.
              </p>

              <p className="para">
                Developing new apps with Salesforce is not only fast — it’s
                cost-effective, key to the financial stewardship that is part of
                the DIR’s mission. “Salesforce helps us ensure that every dollar
                is spent as effectively as possible,” Kimbriel explains. “With
                Salesforce we can continue to add more and more apps for the
                same fixed fee, locking down costs but expanding the technology
                value.” And, since many of the apps are built by administrators,
                the agency is spared the staffing costs of more specialised
                developers.
              </p>

          </Grid>
        </div>
      </Grid>

      <div className="full-page-image4">
        <img src={ps2} alt="Full Page Image4" />
      </div>
    </Container>
  );
};

export default EventTemplate;
